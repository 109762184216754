<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row no-gutters justify="center">
      <v-col
        cols="11"
        lg="10"
        class="text-left mt-10 mb-10 reform_primary--text text-h4"
      >
        신청서 관리
      </v-col>
      <v-col cols="11" lg="10" class="text-left mb-10 reform_primary--text">
        <v-container fluid>
          <!-- 날짜 버튼 탭 -->
          <v-row justify="space-between" no-gutters>
            <v-col cols="12" lg="4" class="pr-1 mt-1">
              <v-row no-gutters>
                <!-- 텍스트필드 1번 -->
                <v-col>
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    @click="pickerOffset(0)"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        ref="dateInputFirst"
                        v-model="conditional_date_array[0]"
                        hide-details
                        outlined
                        dense
                        rounded
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      no-title
                      flat
                      color="reform_primary"
                      header-color="reform_primary"
                      :weekday-format="dayformat"
                      :month-format="monthformat"
                      :header-date-format="getHeaderTitleMonth"
                      v-model="picker_first"
                      @change="click_picker(0)"
                      @input="menu1 = false"
                      class="mt-4"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- ~ -->
                <v-col cols="auto" class="pr-1 pl-1 pt-2 font-weight-bold">
                  ~
                </v-col>
                <!-- 텍스트필드 2번 -->
                <v-col>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    @click="pickerOffset(1)"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        ref="dateInputFirst"
                        v-model="conditional_date_array[1]"
                        hide-details
                        outlined
                        dense
                        rounded
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      no-title
                      flat
                      color="reform_primary"
                      header-color="reform_primary"
                      :weekday-format="dayformat"
                      :month-format="monthformat"
                      :header-date-format="getHeaderTitleMonth"
                      v-model="picker_second"
                      :allowed-dates="allowedDates"
                      @change="click_picker(1)"
                      @input="menu2 = false"
                      class="mt-4"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" class="pr-1 mt-1">
              <v-row no-gutters justify="space-between">
                <!-- btn 당월 -->
                <v-col class="pr-1">
                  <v-btn
                    width="100%"
                    color="reform_primary"
                    rounded
                    elevation="0"
                    dark
                    height="40px"
                    @click="conditional_date('month')"
                    class="btn-font"
                  >
                    당월
                  </v-btn>
                </v-col>
                <!-- btn 1달전 -->
                <v-col class="pr-1">
                  <v-btn
                    width="100%"
                    color="reform_primary"
                    rounded
                    elevation="0"
                    dark
                    height="40px"
                    @click="conditional_date('month_back')"
                    class="btn-font"
                  >
                    1달전
                  </v-btn>
                </v-col>
                <!-- btn 3달전 -->
                <v-col class="pr-1">
                  <v-btn
                    width="100%"
                    color="reform_primary"
                    rounded
                    elevation="0"
                    dark
                    height="40px"
                    @click="conditional_date('3month_back')"
                    class="btn-font"
                  >
                    3달전
                  </v-btn>
                </v-col>
                <!-- btn 전체 -->
                <v-col class="pr-0">
                  <v-btn
                    width="100%"
                    color="reform_primary"
                    rounded
                    elevation="0"
                    dark
                    height="40px"
                    @click="conditional_date('all')"
                    class="btn-font"
                  >
                    전체
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" class="mt-1 search-col">
              <v-row no-gutters justify="end" class="search-col-row">
                <v-col cols="auto" class="search-col-btn">
                  <v-btn
                    color="reform_primary"
                    rounded
                    elevation="0"
                    dark
                    width="120px"
                    height="40px"
                    @click="clickSearch()"
                  >
                    검색
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- 탭끝 -->
          <!-- 리스트 -->
          <div class="scroll-body mt-10">
            <div class="scroll-content">
              <v-row
                no-gutters
                class="pt-2 pb-2 bb-lightgray grey--text"
                align="center"
              >
                <v-col cols="3" class="text-center"> 신청서 ID </v-col>
                <v-col cols="3" class="text-center"> 신청날짜 </v-col>
                <v-col cols="3" class="text-center"> 신청자 ID </v-col>
                <v-col cols="3" class="text-center"> 진행 상태 </v-col>
              </v-row>
              <v-hover
                v-slot="{ hover }"
                v-for="(item, index) in list"
                :key="index + 'A'"
              >
                <v-row
                  v-show="list.length > 0"
                  style="font-size: 18px; border-bottom: 1px solid lightgray"
                  class="receive-patient-list pt-2 pb-2"
                  no-gutters
                  align="center"
                  @click="selectList(item, index)"
                  :style="hover ? 'background-color:#f5f9fa' : ''"
                >
                  <v-col cols="3" class="text-center">
                    {{ item.aplyId }}
                  </v-col>
                  <v-col cols="3" class="text-center">
                    {{ item.aplyDt }}
                  </v-col>
                  <v-col cols="3" class="text-center">
                    {{ item.aplyDrId }}
                  </v-col>
                  <v-col cols="3" class="text-center">
                    {{ getStatCd(item.prgsStatCd) }}
                  </v-col>
                </v-row>
              </v-hover>
              <v-row
                v-show="list.length === 0"
                align="center"
                justify="center"
                class="pt-16 pb-16"
                no-gutters
              >
                <div class="notification-list none-border">
                  <div class="item pt-16 pb-16">
                    <div class="logo-wrap pt-16 pb-16">
                      <v-img src="@/assets/images/logo_unplugged.svg"></v-img>

                      <h4>조회결과 없음</h4>
                    </div>
                  </div>
                </div>
              </v-row>
            </div>
          </div>
          <!-- 리스트끝 -->
          <v-row
            justify="center"
            no-gutters
            v-show="list.length !== 0"
            class="mt-5"
          >
            <!-- 페이지네이션 < -->
            <v-col cols="auto">
              <v-btn
                class="ma-1 text-h6"
                fab
                @click="navigatorAct('prev')"
                v-if="listStatus.first > 1"
                elevation="0"
                x-small
              >
                <v-icon large color="reform_primary"> mdi-chevron-left </v-icon>
              </v-btn>
            </v-col>
            <!-- < 끝 -->
            <!-- 페이지네이션 숫자 -->
            <v-col cols="auto">
              <v-btn
                class="ma-1 text-h6"
                fab
                v-for="(pagination, index) in listStatus.currentPaginationList"
                :key="index + 'j'"
                :color="
                  index === (listStatus.currentPage - 1) % 5
                    ? 'reform_primary'
                    : ''
                "
                :dark="
                  index === (listStatus.currentPage - 1) % 5 ? true : false
                "
                @click="loadList(pagination, index)"
                elevation="0"
                x-small
                >{{ pagination }}
              </v-btn>
            </v-col>
            <!-- 숫자끝 -->
            <!-- 페이지네이션 > -->
            <v-col cols="auto">
              <v-btn
                class="ma-1 text-h6"
                fab
                @click="navigatorAct('next')"
                v-if="
                  listStatus.next > 5 && listStatus.last < listStatus.totalPage
                "
                elevation="0"
                x-small
              >
                <v-icon large color="reform_primary">
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-col>
            <!-- > 끝 -->
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </v-container>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import DefaultPopup from "../modal/DefaultPopup.vue";
export default {
  components: { DefaultPopup },
  data() {
    return {
      popupSet: {},
      picker_first: "",
      picker_second: "",
      menu1: false,
      menu2: false,
      picker_first_offset: [0, 0],
      picker_second_offset: [0, 0],
      list: [],
      searchOpt: {
        receiveOrder: 0, // default 옵션,
        recordsPerPage: "", // 한번에 불러올 갯수
      },
      conditional_date_array: [null, null],
      listStatus: {
        totalCount: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
      },
    };
  },
  computed: {
    ...mapState("basic", ["checkPopup", "isChildLoading"]),
    ...mapGetters("request", ["GET_APPLY_LIST"]),
  },
  mounted() {
    this.reload();
  },
  methods: {
    ...mapActions("request", ["BRING_APPLY_LIST"]),
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    async conditional_date(type) {
      if (type === "all") {
        this.conditional_date_array = ["전체", "전체"];
      } else if (type === "month") {
        this.conditional_date_array = [
          this.$moment().subtract(0, "M").format("YYYYMM") + "01",
          this.$moment().format("YYYYMMDD"),
        ];
      } else if (type === "month_back") {
        this.conditional_date_array = [
          this.$moment().subtract(1, "M").format("YYYYMMDD"),
          this.$moment().format("YYYYMMDD"),
        ];
      } else if (type === "3month_back") {
        this.conditional_date_array = [
          this.$moment().subtract(3, "M").format("YYYYMMDD"),
          this.$moment().format("YYYYMMDD"),
        ];
      }
    },
    async clickSearch() {
      if (this.type === "전체") {
        this.conditional_condition = 0;
      } else if (this.type === "회신 완료") {
        this.conditional_condition = 1;
      } else if (this.type === "내원 완료") {
        this.conditional_condition = 2;
      } else if (this.type === "되의뢰 완료") {
        this.conditional_condition = 3;
      }
      if (
        this.conditional_date_array[0] === "전체" &&
        this.conditional_date_array[1] === "전체"
      ) {
        this.conditional_date_array[0] = null;
        this.conditional_date_array[1] = null;
        this.reload();
      } else if (
        this.conditional_date_array[0] !== undefined &&
        this.conditional_date_array[0] !== null &&
        this.conditional_date_array[1] !== undefined &&
        this.conditional_date_array[1] !== null
      ) {
        if (
          this.$moment(this.conditional_date_array[1]).isAfter(
            this.conditional_date_array[0]
          )
        ) {
          this.reload();
        } else {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content =
            "검색조건이 알맞지 않습니다. 다시한번 확인해 주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content =
          "검색조건이 알맞지 않습니다. 다시한번 확인해 주세요";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    async reload() {
      localStorage.setItem("currentTab", 0);
      this.initListStatus();
      this.list = [];
      const list = [];
      this.listStatus.totalDataCnt = 0;
      this.SET_CHILD_LOADER(true);

      const searchOpt = {
        drId: localStorage.getItem("drId"),
        pageNo: 1,
        recordsPerPage: this.listStatus.dataOnPage,
        pickDateFirst: this.conditional_date_array[0],
        pickDateSecond: this.conditional_date_array[1],
      };

      this.searchOpt.receiveOrder = 0;
      try {
        await this.BRING_APPLY_LIST(searchOpt).then(() => {
          this.listStatus.totalDataCnt = this.GET_APPLY_LIST.param.totalCount;
          for (let i = 0; i < this.GET_APPLY_LIST.list.length; i++) {
            list.push(this.GET_APPLY_LIST.list[i]);
          }
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
      this.list = list;
      this.SET_CHILD_LOADER(false);
      this.preParePagination();
    },
    async selectList(listItem) {
      // console.log("clickList :", listItem);
      if (listItem.prgsStatCd === "10") {
        this.SET_POPUP(true);
        this.popupSet.title = "확인창";
        this.popupSet.content =
          "이미 구매 종결된 신청서입니다. 새 신청서를 작성해 주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      } else if (listItem.prgsStatCd === "20") {
        this.SET_POPUP(true);
        this.popupSet.title = "확인창";
        this.popupSet.content =
          "결제취소된 신청서입니다. 새 신청서를 작성해 주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      } else if (listItem.prgsStatCd === "30") {
        this.SET_POPUP(true);
        this.popupSet.title = "확인창";
        this.popupSet.content =
          "결제진행중 취소된 신청서입니다. 새 신청서를 작성해 주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      } else if (listItem.prgsStatCd === "99") {
        this.SET_POPUP(true);
        this.popupSet.title = "확인창";
        this.popupSet.content =
          "결제 오류로 인해 취소된 신청서입니다. 새 신청서를 작성해 주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      } else {
        this.$router.push("apply").catch(() => {});
      }
    },
    initListStatus() {
      this.listStatus.totalDataCnt = null;
      this.listStatus.dataOnPage = 10;
      this.listStatus.pageCount = 5;
      this.listStatus.totalPage = null;
      this.listStatus.currentPage = 1;
      this.listStatus.prev = null;
      this.listStatus.next = null;
      this.listStatus.first = null;
      this.listStatus.last = null;
      this.listStatus.currentPaginationList = [];
    },
    click_picker(str) {
      if (str === 0) {
        // console.log(this.picker_first);
        const [year, month, day] = this.picker_first.split("-");
        // console.log(`${year}${month}${day}`);
        this.conditional_date_array[0] = `${year}${month}${day}`;
      } else if (str === 1) {
        // console.log(this.picker_second);
        const [year, month, day] = this.picker_second.split("-");
        // console.log(`${year}${month}${day}`);
        this.conditional_date_array[1] = `${year}${month}${day}`;
      }
    },
    preParePagination() {
      const totalDataCnt = this.listStatus.totalDataCnt;
      const currentPage = this.listStatus.currentPage;
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalDataCnt / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
    },
    calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      for (let i = 0; i <= 5; i++) {
        if (last % 5 !== 0) {
          if (paginationListArray[0] % 5 !== 1) {
            paginationListArray.shift();
          } else {
            this.listStatus.first = paginationListArray[0];
            break;
          }
        }
      }

      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        this.$nextTick((_) => {
          if (this.listStatus.last % 5 !== 0) {
            let num = this.listStatus.last % 5;
            this.listStatus.last = this.listStatus.last + (5 - num);
            this.loadList(this.listStatus.last, 4);
          } else {
            this.loadList(this.listStatus.last, 4);
          }
        });
      } else if (type === "next") {
        this.loadList(this.listStatus.currentPage, 0);
      }
    },
    allowedDates: (val) =>
      val >=
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    dayformat(date) {
      const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    monthformat(date) {
      const monthName = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ];

      let i = new Date(date).getMonth(date);
      return monthName[i];
    },
    getHeaderTitleMonth(date) {
      let year = date.split("-")[0];
      let month = date.split("-")[1];
      if (month === undefined) month = "";
      else {
        month = parseInt(month);
        month = "." + month + "월";
      }
      return year + month;
    },
    getStatCd(val) {
      let result;
      switch (val) {
        case "00":
          result = "신청서작성";
          break;
        case "01":
          result = "주문완료";
          break;
        case "02":
          result = "취소불가";
          break;
        case "03":
          result = "응답대기";
          break;
        case "04":
          result = "전달대기";
          break;
        case "05":
          result = "전달완료";
          break;
        case "10":
          result = "구매확정";
          break;
        case "20":
          result = "결제취소";
          break;
        case "30":
          result = "결제중단";
          break;
        case "99":
          result = "결제실패";
          break;
      }
      return result;
    },
  },
};
</script>

<style></style>
